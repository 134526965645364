import { Link } from "react-router-dom";
import { qreq } from "../shared/qrequest";
import AppActions from "./AppActions";
import BaseCom from "./common/BaseCom";
import DataTable from "./common/DataTable";
import Icon from "./common/Icon";
import { withParamsAndNavigate } from "./common/WithParamsAndNavigate";
import { NavMenuGlobal } from "./NavMenu";
import { Pop } from "./common/PopModal";

class InstanceList extends BaseCom {
    componentDidMount() {
        NavMenuGlobal.setPath('Instances');

        qreq.get('/api/instance/list', j => {
            if (j.list)
                this.setState({ list: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    delete = (item) => {
        Pop.showConfirm(`Are you sure you want to delete '${item.name}' instance?`, 'Delete Instance', () => {
            qreq.post('/api/instance/delete', { id: item.id }, j => {
                if (j.errorCode === 0) {
                    this.success('Instance deleted successfully.');
                    this.componentDidMount();
                }
                else
                    this.alert(j.errorMessage);
            }, this.unkownErrorCallback);
        });
    }

    render() {
        return <div className="container-fluid">
            <AppActions>
                <Link to="/console/instance/new" type="button" className="btn btn-primary btn-lg"><Icon icon="faPlus" /></Link>
            </AppActions>
            <DataTable columns={[
                { name: 'id', title: 'ID', width: '100px' },
                {
                    name: 'name', title: 'Name', onClick: row => {
                        this.props.navigate(`/console/instance/edit/${row.id}`);
                    }
                },
                {
                    name: 'actions',
                    valueFunc: row => <div className="d-flex gap-1">
                        <Link to={`/console/instance/edit/${row.id}`} className="btn btn-primary"><Icon icon="faPencil" /></Link>
                        <button type="button" onClick={() => this.delete(row)} className="btn btn-danger"><Icon icon="faTrash" /></button>
                    </div>,
                    width: '100px'
                }
            ]} data={this.state.list} />
        </div>;
    }
}

export default withParamsAndNavigate(InstanceList);