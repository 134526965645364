import React from 'react';
import BaseComponent from './common/BaseCom';
import FormInput from './common/FormInput';
import { withParamsAndNavigate } from './common/WithParamsAndNavigate';
import { qreq } from '../shared/qrequest';
import Globals from '../shared/Globals';
import Auth from '../shared/Auth';
import { Link } from 'react-router-dom';
import Card from './common/Card';
import './Login.css';
import L from './common/Lang';

class Login extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            credentials: {},
            submitting: false
        };
        this.submit = this.submit.bind(this);
        this.setUser = props.setUser;
    }

    componentDidMount() {
        Auth.getLogin(u => {
            if (u)
                this.props.navigate('/console/dashboard');
            else {
                this.setState({ loaded: true });
            }
        });
    }

    submit(e) {
        e.preventDefault();
        this.setState({ submitting: true });
        qreq.post('/api/auth/login', this.state.credentials, j => {
            if (j.errorCode)
                this.alert(j.errorMessage);
            else if (j.item) {
                Globals.set('user', j.item);
                this.props.navigate('/console/dashboard');
            }
            this.setState({ submitting: false });
        }, e => {
            this.alert('An unkown error ocurred. Login service might be down.');
            this.setState({ submitting: false });
        });
    }

    render() {
        if (!this.state.loaded)
            return <></>;

        return <div className="login">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <div className="p-4">
                            <h1 className="text-center mb-3">ARM Power Admin</h1>
                            <form onSubmit={this.submit}>
                                <Card title="Login">
                                    <FormInput model={this.state.credentials} name="email" label="Email Address" type="text" required={true} labelAsPlaceholder={true} autoComplete="username" />
                                    <FormInput model={this.state.credentials} name="password" label="Password" type="password" required={true} labelAsPlaceholder={true} autoComplete="current-password" />
                                    <div className="d-grid mt-4">
                                        <button type="submit" className="btn btn-primary btn-lg" disabled={this.state.submitting}><L>Login</L></button>
                                    </div>
                                    <div className="my-3 text-center"><Link to="/forgot-password"><L>I forgot my password</L></Link></div>
                                    {this.state.error ? (<div className="alert alert-danger mt-2">{Globals.getLang(this.state.error)}</div>) : ''}
                                </Card>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withParamsAndNavigate(Login);