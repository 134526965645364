import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import { PopModal } from './components/common/PopModal';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Logout from './components/Logout';
import ConsoleLayout from './components/ConsoleLayout';
import Dashboard from './components/Dashboard';
import ForgotPassword from './components/ForgotPassword';
import SetPassword from './components/SetPassword';
import E404 from './components/E404';
import { ImageViewCom } from './components/common/ImageView';
import Globals from './shared/Globals';
import { loadLang, pullCurrentLang } from './shared/GLang';
import Menu from './components/Menu';
import InstanceList from './components/InstanceList';
import InstanceEdit from './components/InstanceEdit';
import UserList from './components/UserList';
import MonitorSync from './components/monitor/MonitorSync';

export const AppGlobal = {
    setLang() {}
};

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'es'
        };
        this.setLang = this.setLang.bind(this);
        AppGlobal.setLang = this.setLang;
    }

    componentDidMount() {
        loadLang(() => {
            pullCurrentLang(lang => {
                this.setState({ lang: lang });
            });            
        });
    }

    setLang(lang) {
        Globals.setLang(lang);
        this.setState({ lang: lang });
    }

    render() {
        return (<>
            <PopModal />
            <ImageViewCom />
            <ToastContainer position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />
            <BrowserRouter lang={this.state.lang}>
                <Routes>
                    <Route index element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:code" element={<SetPassword />} />
                    <Route path="/console" element={<ConsoleLayout lang={this.state.lang} />}>
                        <Route index element={<Dashboard lang={this.state.lang} />} />
                        <Route path="menu" element={<Menu lang={this.state.lang} />} />
                        <Route path="dashboard" element={<Dashboard lang={this.state.lang} />} />

                        {/* Instances */}
                        <Route path="instance" element={<InstanceList lang={this.state.lang} />} />
                        <Route path="instance/new" element={<InstanceEdit lang={this.state.lang} />} />
                        <Route path="instance/edit/:id" element={<InstanceEdit lang={this.state.lang} />} />

                        {/* Users */}
                        <Route path="user" element={<UserList lang={this.state.lang} />} />

                        {/* Monitor */}
                        <Route path="monitor" element={<MonitorSync lang={this.state.lang} />} />

                        <Route path="*" element={<E404 fluid />} />
                    </Route>
                    <Route path="*" element={<E404 />} />
                </Routes>
            </BrowserRouter>
            </>
        );
    }
}
