import { qreq } from "../shared/qrequest";
import AppFormActions from "./AppFormActions";
import BaseCom from "./common/BaseCom";
import Card from "./common/Card";
import FormInput from "./common/FormInput";
import Icon from "./common/Icon";
import { NavMenuGlobal } from "./NavMenu";

class InstanceEdit extends BaseCom {
    constructor(props) {
        super(props);

        this.state = {
            info: {},
            hosts: []
        };
    }

    componentDidMount() {
        NavMenuGlobal.setPath('Edit Instance');
        this.loadHosts();
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    loadHosts = () => {
        qreq.get('/api/instance/host/list', j => {
            if(j.list)
                this.setState({ hosts: j.list });
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    onChange = (v, doGen) => {
        var info = {...this.state.info};
        if(doGen) {
            info.ident = v.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
            info.dbname = info.ident;
        }
        this.setState({ info: info });
    }

    submit = (e) => {
        e?.preventDefault();
        this.setState({ submitting: true });
        const info = {...this.state.info};
        const host = this.state.hosts.find(i => i.id === Number(info.dbhostID));
        info.dbhost = host.hostname;
        qreq.post('/api/instance/save', info, j => {
            if(j.errorCode === 0) {
                this.success('Instance saved successfully.');
                this.goBack();
            }
            else
                this.alert(j.errorMessage);
            this.setState({ submitting: false });
        }, () => {
            this.setState({ submitting: false });
            this.unkownErrorCallback();
        });
    }

    render() {
        return <div className="container-fluid">
            <form onSubmit={this.submit}>
                <AppFormActions showBackButton>
                    <button type="submit" className="btn btn-primary btn-lg" disabled={this.state.submitting}><Icon icon="faSave" /> Save</button>
                </AppFormActions>
                <Card title="Instance Info">
                    <FormInput model={this.state.info} name="name" label="Client Name" type="text" onChange={v => this.onChange(v, true)} required />
                    <FormInput model={this.state.info} name="ident" label="Instance Identity" type="text" inputGroupClassName="mb-0" onChange={this.onChange} required />
                    <div className="text-muted mb-3">{this.state.info.ident}.salesconsole.app</div>
                </Card>
                <Card title="Database Info">
                    <FormInput model={this.state.info} name="dbhostID" label="Database Host" type="select" onChange={this.onChange} options={this.state.hosts} required />
                    <FormInput model={this.state.info} name="dbname" label="Database Name" type="text" onChange={this.onChange} required />
                </Card>
            </form>
        </div>;
    }
}

export default InstanceEdit;