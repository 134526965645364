import { qreq } from "../shared/qrequest";
import BaseCom from "./common/BaseCom";
import Alert from "./common/Alert";
import { withParamsAndNavigate } from "./common/WithParamsAndNavigate";

class Logout extends BaseCom {

    componentDidMount() {
        qreq.post('/api/auth/logout', {}, j => {
            if (j.errorCode === 0)
                window.location.assign('/');
            else
                this.alert(j.errorMessage);
        }, this.unkownErrorCallback);
    }

    render() {
        return <><Alert message="Please wait..." /></>;
    }
}

export default withParamsAndNavigate(Logout);