import BaseCom from "./common/BaseCom";
import FormInput from './common/FormInput';
import L from './common/Lang';
import { qreq } from '../shared/qrequest';
import Alert from './common/Alert';
import Auth from '../shared/Auth';
import { withParamsAndNavigate } from './common/WithParamsAndNavigate';
import Card from './common/Card';

class SetPassword extends BaseCom {

    constructor(props) {
        super(props);
        this.state = {
            info: {},
            confirm: true
        };
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        Auth.getLogin(u => {
            if (u)
                this.props.navigate('/console');
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show)
            this.setState({ info: {} });
        if (prevProps.info !== this.props.info)
            this.setState({ info: {} })
    }

    submit(e) {
        e.preventDefault();
        if (!this.state.confirm) {
            this.setState({ confirm: true });
            return;
        }
        if (this.state.info.password !== this.state.info.confirmPassword) {
            this.alert('Passwords do not match.');
            return;
        }
        this.setState({ submitting: true });
        qreq.post('/api/auth/reset-password', { ...this.state.info, confirmPassword: this.state.info.password, code: this.props.params.code }, j => {
            if (j.errorCode === 0) {
                this.setState({ success: true, info: {} });
            }
            else
                this.alert(j.errorMessage);
            this.setState({ submitting: false });
        }, () => {
            this.alert(<L>Unknown error ocurred, please try again.</L>);
            this.setState({ submitting: false });
        });
    }

    render() {
        return <div className="login">
            <div className="container SetPassword">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-4">
                        <Card title="Change Password">
                            {this.state.success ? <>
                                <p><L>Your password has been changed.</L></p>
                                <div className="d-grid gap-2">
                                    <button type="button" className="btn btn-secondary btn-lg" onClick={() => { if (this.state.success) document.location.replace('/console'); if (this.props.onToggle) this.props.onToggle(); }}><L>Continue</L></button>
                                </div>
                            </> :
                                <form onSubmit={this.submit}>
                                    <FormInput model={this.state.info} name="password" label="New Password" type="password" required className="mt-2" autoComplete="password" />
                                    {this.state.confirm ? <FormInput model={this.state.info} name="confirmPassword" label="Confirm New Password" type="password" required className="mt-2" /> : ''}
                                    <div className="d-grid gap-2">
                                        <button type="submit" className="btn btn-primary btn-lg" disabled={this.state.submitting}>{this.state.confirm ? <L>Change password</L> : <L>Continue</L>}</button>
                                    </div>
                                    {this.state.error ? <Alert message={this.state.error} type="danger" className="mt-2" /> : ''}
                                </form>}
                        </Card>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withParamsAndNavigate(SetPassword);