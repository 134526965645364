import { Link } from "react-router-dom";
import Auth from "../shared/Auth";
import BaseCom from "./common/BaseCom";
import L from "./common/Lang";
import Icon from "./common/Icon";
import { withParamsAndNavigate } from "./common/WithParamsAndNavigate";
import Globals from "../shared/Globals";

class Menu extends BaseCom {
    componentDidMount() {
        Auth.getLogin(u => {
            this.setState({ user: u });
            this.fetchNav();
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.lang !== prevProps.lang) {
          this.fetchNav();
        }
      }

    render() {
        if (!this.state.nav)
            return;

        return <div className="container-fluid">
            <div className="p-2">
            {this.state.user ? <p className="lead text-end"><L>Hello</L> <span className="badge bg-success"><Icon icon="faUser" /> <span className="text-capitalize">{this.state.user.user1.user_name.toLowerCase()}</span></span></p> : null}
            <p className="text-secondary my-3"><L>Main</L></p>
            <div className="list-group app-home-menu box-shadow rounded">
                {this.state.nav.map(i => <Link key={i[0]} to={i[2]} className="list-group-item">
                    <Icon icon={i[3]} />{i[1]}
                </Link>)}
            </div>
            <p className="text-secondary my-3"><L>Account</L></p>
            <div className="list-group app-home-menu box-shadow rounded">
                <span role="link" className="list-group-item" onClick={this.logout}>
                    <Icon icon="faArrowRightFromBracket" /> <L>Logout</L>
                </span>
            </div>
        </div>
        {Globals.canInstall() && !this.state.installed ? <div className="text-center">
            <p className="text-secondary my-3"><L>Want to install this as a standalone application?</L></p>
            <button type="button" className="btn btn-primary btn-lg" onClick={() => Globals.showInstall(() => this.setState({ installed: true }))}><Icon icon="faCircleDown" /> <L>Install APP</L></button>
            </div> : null}
        </div>;
    }
}

export default withParamsAndNavigate(Menu);