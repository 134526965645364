import React from 'react';
import Alert from './common/Alert';

export default class E404 extends React.Component {

    render() {
        return <div className={this.props.fluid ? 'container-fluid' : 'container'}>
            <h2>Error 404</h2>
            <Alert type="danger" message="Page not found." icon="ExclamationTriangle" />
        </div >;
    }
}