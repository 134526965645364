import { Outlet } from "react-router-dom";
import BaseCom from "./common/BaseCom";
import { NavMenu } from "./NavMenu";
import { withParamsAndNavigate } from "./common/WithParamsAndNavigate";
import ScrollToTop from './common/ScrollToTop';
import Auth from "../shared/Auth";

class ConsoleLayout extends BaseCom {
  constructor(props) {
    super(props);
    this.state = {
      showNavbar: false
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  componentDidMount() {
    Auth.getLogin(u => {
      this.setState({ user: u });
      if (!u)
        this.props.navigate('/login');
    })
  }

  toggleNavbar(on) {
    this.setState({ showNavbar: on });
  }

  render() {
    return <div className={this.state.showNavbar ? '' : 'app-navbar-hidden'}>
      <ScrollToTop />
      <NavMenu toggleNavbar={this.toggleNavbar} showNavbar={this.state.showNavbar} lang={this.props.lang} />
      <div className="app-body">
        <Outlet />
      </div>
    </div>;
  }
}

export default withParamsAndNavigate(ConsoleLayout);