import { qreq } from "../../shared/qrequest";
import BaseCom from "../common/BaseCom";
import Icon from "../common/Icon";
import L from "../common/Lang";
import { Pop } from "../common/PopModal";
import { withParamsAndNavigate } from "../common/WithParamsAndNavigate";

class MonitorSync extends BaseCom {

    restart = () => {
        Pop.showConfirm('Are you sure you want to restart PGSyncSS Server?', 'Restart PGSyncSS Server', async () => {
            try {
                let j = await qreq.postAsync('/api/monitor/restart/pgsyncss', {});
                if(j.errorCode === 0)
                    Pop.show('PGSyncSS Server restarted successfully', 'Success');
                else
                    Pop.show('Failed to restart PGSyncSS Server', 'Error');
            }
            catch(e) {
                Pop.show('Failed to restart PGSyncSS Server', 'Error');
            }
        });
    }

    render() {
        return (
            <div className="container-fluid">
                <button type="button" className="btn btn-secondary btn-lg" onClick={this.restart}>
                    <Icon icon="faRefresh" /> <L>Restart</L>
                </button>
            </div>
        )
    }
}

export default withParamsAndNavigate(MonitorSync);