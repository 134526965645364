import BaseCom from "./common/BaseCom";
import { withParamsAndNavigate } from "./common/WithParamsAndNavigate";
import { NavMenuGlobal } from "./NavMenu";
import { qreq } from "../shared/qrequest";
import Globals from "../shared/Globals";

class Dashboard extends BaseCom {
    constructor(props) {
        super(props);
        this.state = {
            orders: null,
            salesByDay: null,
            salesByMonth: null,
            totalSalesToday: null,
            total30Days: null,
            total12Months: null
        };
        this.pullChartData = this.pullChartData.bind(this);
    }
    componentDidMount() {
        NavMenuGlobal.setPath('Dashboard');
        qreq.get('/api/order/recent/list?dt=' + Globals.getLocalDateString(), j => {
            if (j.list)
                this.setState({ orders: j.list });
        });
        this.pullChartData();
    }

    componentWillUnmount() {
        NavMenuGlobal.clearTitle();
    }

    pullChartData() {
        // Totals
        qreq.get('/api/chart/total-sales-today?dt=' + Globals.getLocalDateString(), j => {
            if (j.item) {
                this.setState({ totalSalesToday: j.item });
            }
        });
        qreq.get('/api/chart/total-ar-today?dt=' + Globals.getLocalDateString(), j => {
            if (j.item) {
                this.setState({ totalSalesToday: j.item });
            }
        });

        // Charts
        qreq.get('/api/chart/sales-by-day?dt=' + Globals.getLocalDateString(), j => {
            if (j.list) {
                var total = 0;
                j.list.forEach(i => total += i.dx);
                this.setState({ salesByDay: j.list, total30Days: total });
            }
        });
        qreq.get('/api/chart/sales-by-month?dt=' + Globals.getLocalDateString(), j => {
            if (j.list) {
                var total = 0;
                j.list.forEach(i => total += i.dx);
                this.setState({ salesByMonth: j.list, total12Months: total });
            }
        });
    }

    render() {
        return <div className="container-fluid">
            <p>TODO</p>
        </div>;
    }
}

export default withParamsAndNavigate(Dashboard);